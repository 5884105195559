.erreur-conso-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;

    p {
        color: var(--ion-color-dark);
        margin: 5px;
        text-align: center;
    }

    .backlink {
        display: block;
        margin: 2.5rem auto 0;
        text-align: center;

        &:hover {
            text-decoration: none;
        }
    }
}
