@import "../theme/vars";

* {
  font-family: "Montserrat";
}

.rent_page {
  padding: 25px 0 25px 16px;
  border-radius: 14px;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;
}

.rent_page p, .rent_page .payment_text {
  font-size: 0.8em;
  color: var(--ion-color-light-contrast);
}

.rent__total h2 {
  font-size: 0.8em;
  color: var(--ion-color-light-contrast);
  margin: 0;
  font-weight: 600;
}

.rent__title {
  font-family: "Montserrat";
}

.rent__price {
  color: var(--ion-color-light-contrast);
}

#rentAmount {
  color: var(--ion-color-medium);
}

.payment_form {
  margin-right: 16px;
}

//DL BUTTON
.automatic-payment_button-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.base-button.automatic-payment_button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;

  text-align: left;
  font-size: 0.7em;
  font-weight: normal;
}

.auto-payment_span {
  font-weight: 600;
  font-size: 1.3em;
  text-transform: uppercase;
}

.calendar_icon {
  margin-right: 15px;
}

//INPUT PAYMENT

.input_rent-amount p {
  color: $redText;
  position: absolute;
  text-align: center;
  transform: translateY(-100px) translateX(-10px);
}

.payment {
  display: flex;
  align-items: center;

  justify-content: space-between;
  margin-top: 50px;
}

.input_payment {
  margin-top: 0;
  height: 55px;
  padding: 0 20px;
  width: 140px;
  font-weight: 600;

  .input-row .input {
    width: 100%;
  }

  .side::before {
    display: none;
  }

  .side-item {
    display: none;
  }
}

.payment_text {
  font-size: 0.8em;
  font-weight: 600;
}

.rent_pay_unauthorized {
  color: var(--ion-color-warning-shade) !important;
}

//PAYMENT BUTTON

.rent_pay-button {
  max-width: 400px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.9em;
}

.card_icon {
  width: 30px;
  margin-right: 10px;
}

.rent_pay-button {
  margin-top: 10px;
  font-size: 0.8em;
  padding: 1em;
}

@media screen and (min-width: 768px) {
  .rent_page {
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 0 30px 40px;
  }

  .payment_form {
    margin-right: 30px;
  }

  .rent_total-container {
    height: 130px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .rent_info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 270px;
  }
  .rent_button-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 270px;
    margin-left: 30px;
  }

  .base-button.automatic-payment_button {
    margin-top: 0;
    font-size: 0.8em;
  }

  .input_rent-amount p {
    color: $redText;
    width: 250px;
    position: absolute;
    text-align: center;
    transform: translateY(-90px) translateX(-100px);
  }

  .payment {
    margin-top: 20px;
  }
  .rent_pay-button {
    margin-top: 10px;
  }
}

@media screen and (min-width: 869px) {
  .rent_pay-button {
    padding: 1em;
  }
}
