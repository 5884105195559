@import "../theme/vars";

.container_profile {
  background-color: var(--ion-color-light);
}
.infos-profile-container {
  &--delete-section {
    position: relative;
    z-index: 2000;
    margin-top: 30px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    max-width: 90%;
    background-color: var(--ion-color-primary-contrast);
    box-shadow: 0 0 10px var(--ion-color-dark-tint);
    @media screen and (min-width: 768px) {
      max-width: 50%;
    }
    & > button {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0 6px;
      min-height: 2em;
      background: none;
      &:hover,
      &:focus {
        font-weight: bold;
        text-decoration: underline;
      }
      &.dark {
        color: var(--ion-color-dark);
      }
    }
  }
  &-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1010;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-medium-shade);
  }
}

.account_infos-profil > * {
  margin: 24px 0;
}

.account_infos-profil {
  p:not(.input-error) {
    font-size: 0.8em;
    color: var(--ion-color-light-contrast);
    &.modifier_p {
      color: var(--ion-color-medium);
    }
    span {
      color: var(--ion-color-light-contrast);
      font-weight: 600;
    }
  }
  ion-icon {
    color: var(--ion-color-success-contrast) !important;
  }
}

.container__input--profile {
  width: inherit;

  .side-item {
    width: unset !important;
    min-width: unset !important;
  }

  .side-item_left {
    display: none !important;
  }

  @media screen and (min-width: 768px) {
    margin-left: 55px;
  }

  .input-wrapper {
    margin-top: 0 !important;
    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }

    &.side-item {
      display: none;
    }
  }

  &--password {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    border: solid $border 1px;
    height: 54px;
    padding: 0 16px;

    margin-left: 0px;
    font-family: "Montserrat", sans-serif;

    @media screen and ( min-width : 768px) {
      max-width: 428px;
    }

    input {
      width: 100%;
      font-size: 20px !important;
      flex: 1;
      padding: 0;
      height: 100%;
      font-size: 13px;
      border: 0;
      background: transparent;
    }
  }
}

.profil__input--password .side::before,
.profil__input--password .side-item_left {
  display: none !important;
}

.email_input-profile {
  margin-top: 0;
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-weight: 600;

  .input-row .input {
    width: 100%;
  }

  .side::before {
    display: none;
  }

  .side-item {
    display: none;
  }
}

.profil__input--password {
  padding: 0 20px;
}

.password_input-profile {
  margin-top: 0;
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-weight: 600;
}

.input-label_profile {
  font-size: 0.8em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--ion-color-light-contrast) !important;
}

.tenant_profile {
  margin-top: 45px;
  h2 {
    font-size: 0.8em;
  }
}

.auto-payment_profile {
  margin-bottom: 30px;
}

.auto-payment_profile h2 {
  font-size: 1.3em;
  color: var(--ion-color-dark);
  font-weight: bold;
}

.automatic-payment_button-profile {
  min-height: 80px;
}

.automatic-payment_button-profile_alone {
  min-height: 80px;
}

.payment_deadline {
  height: auto;
  display: flex;
  align-items: center;
}

.auto-payment_profile p {
  font-size: 0.8em;
  margin-left: 8px;
}

.auto-payment_profile-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.auto-payment_profile-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title_profile {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .title_profile {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .infos-profile-container {
    display: flex;
    align-items: flex-start;

    p {
      width: 200px;
    }
  }

  .email_input-profile_container {
    margin-top: 40px;
  }

  .automatic-payment_button-profile {
    margin-left: 100px;
  }

  .automatic-payment_button-profile_alone {
    margin-left: 0px;
  }

  .auto-payment_profile-container {
    flex-direction: row;
  }
  .auto-payment_profile h2 {
    margin-bottom: 20px;
  }
}

.my-profil_subtitle {
  font-size: 0.8em;
  color: $greyBorder;
  margin-left: 1em;
  @media screen and (min-width: 768px) {
    margin-left: 0em;
  }
}

.my-profil-delete-section {
  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--ion-color-dark-tint);
    & > small {
      margin: 20px 0;
      font-weight: 600;
      display: inline-block;
      color: var(--ion-color-light-contrast);
      @media screen and (min-width: 768px) {
        width: 70%;
      }
    }
    & > p {
      margin-bottom: 20px;
      width: 70% !important;
      @media screen and (max-height: 768px) {
        margin-bottom: 10px;
      }
      & > strong > span {
        display: block;
        color: var(--ion-color-danger-shade) !important;
      }
    }
  }
  &-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1.5em;
    width: auto;

    @media screen and (max-height: 768px) {
      margin-top: 20px;
    }

    & > button {
      min-height: 2.5em;
      min-width: 8em;
      padding: 0 1em;
      background: transparent;
      border-radius: 8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &:focus {
        text-decoration: underline;
        font-weight: bold;
      }
    }
    & > .delete-btn {
      background: var(--ion-color-danger);
      border: 1px solid var(--ion-color-danger);

      &.light {
        color: #fff;
      }
    }
    & > .abort-btn {
      &.light {
        color: var(--ion-color-secondary);
        border: 2px solid var(--ion-color-secondary);
      }
      &.dark {
        color: var(--ion-color-dark);
        border: 2px solid var(--ion-color-dark);
      }
    }
  }
}

.my-profil-delete-btn {
  color: var(--ion-color-dark);
  min-height: 2em;
  background: transparent;
  transition: font-weight 0.1s ease-in-out, -webkit-text-decoration;
  transition: text-decoration, font-weight 0.1s ease-in-out;
  transition: text-decoration, font-weight 0.1s ease-in-out,
    -webkit-text-decoration;
  &:hover,
  &:focus {
    text-decoration: underline;
    font-weight: bold;
  }
}

.password_input-profile_container {
  position: relative;

  .confirm.input-label_profile {
    position: relative;

    @media screen and (min-width: 768px) {
      position: absolute;
      left: 0;
    }
  }
}
