@import "../../theme/vars";

* {
  font-family: "Montserrat";
}

.rent_content {
  padding-top: 60px;
  background-color: var(--ion-color-light);
}
.rent__title {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--ion-color-dark);
  font-size: 20px;
  @media screen and (min-width: 768px) {
    font-size: 30px;
  }
  &.rent_title_details {
    font-size: 20px;
  }
}
