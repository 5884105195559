@import "../../theme/vars";

.dashboard {
  background: var(--ion-color-light);
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--ion-color-dark);
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
    .rent__title {
      font-size: 20px;
    }
  }

  .accomodation-list_element {
    display: block;
}

  .dashboard__row {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .dashboard__row > *:not(:last-child) {
    margin-bottom: 22px;
  }

  @media screen and (min-width: 1024px) {
    .dashboard__row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .dashboard__row > * {
      flex: 1;
    }

    .dashboard__row > *:not(:last-child) {
      margin-right: 22px;
      margin-bottom: 0;
    }
  }
}
