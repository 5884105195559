@import "../theme/vars";

.disconnect_title {
  text-align: center;
  font-size: 21px;
  font-weight: 500;
}

.disconnect_button {
  width: 300px;
  height: 70px;
  border-radius: 14px;
  margin: 20px;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
}

.return {
  color: var(--ion-color-medium-contrast);
  background-color: var(--ion-color-success);
}

.disconnect {
  color: var(--ion-color-success);
  background-color: var(--ion-color-medium-contrast);
  border: 2px solid var(--ion-color-success);
  border-radius: 14px;
}

.button-container_disconnect {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .button-container_disconnect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
