@import "../theme/vars";

ion-toggle {
  --background: #b0b7bc;
  --handle-background: #46535d;
  --background-checked: #363636;
  --handle-background-checked: #fff;
}

.toggleFocus::part(native)::after {
    background: var(--ion-color-light-contrast);
  }
