.new_page {
  padding: 30px 0 60px;
}

.new_page .back-button {
  max-width: 105px;
  display: flex;
  height: 38px;
  padding: 5px;
  font-weight: 600;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.new_page_content {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}

.news__article--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.news__article__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--ion-color-light-contrast);
  a {
    color: var(--ion-color-light-contrast);
  }
}
