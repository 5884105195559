.documents__table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.documents__table th {
  text-align: left;
  color: var(--ion-color-light-contrast);
  font-weight: 600;
  padding-bottom: 10px;
  &.date__document {
    padding-bottom: 0;
    font-size: 13px;
    color: var(--ion-color-dark);
  }
}

.filter__button {
  height: 20px;
  background: none;
  color: var(--ion-color-light-contrast);
  text-transform: none;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Montserrat";
}

.icon_date {
  width: 8px;
  margin-left: 8px;
  position: absolute;
}

.clicked {
  transform: rotate(180deg);
}

.documents__table td {
  padding: 18px 0;
  font-family: "Montserrat";
  font-size: 13px;
  color: var(--ion-color-light-contrast);
}

.documents__table th {
  min-width: 100px;
  @media screen and (max-width: 412px) {
    min-width: 55px;
  }
}

.documents__table td:last-child {
  width: 100%;
}

.documents__table td:last-child span {
  margin-right: 1em;
  font-family: "Montserrat";
}

.documents__table td:last-child > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.documents__table tbody tr:not(:last-child) {
  border-bottom: 1px solid #e3dfe280;
}

.date__document {
  font-weight: 600;
}

.documents_button-container {
  display: flex;
}
