.tooltip-icon {
  width: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 21px;
  position: relative;
  color: var(--ion-color-success);
  border-radius: 40px;

  &.small {
    font-size: 16px;
  }
}

.tooltip-icon:focus::before {
  outline: none;
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.disabledIcon {
  width: 14px;
  min-width: 14px;
  margin-right: 7px;

  & + .MuiTooltip-popper {
    max-width: 120px;
  }
}
