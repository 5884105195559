@import "../theme/vars";

.fallback {
  width: 100vw;
  height: 70vh;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--ion-color-light-contrast);
  text-align: center;

  span {
    font-weight: 600;
    font-size: 4em;
  }
}

.fallback_button {
  max-width: 250px;
}
