@import "../theme//vars";

div.login {
  width: 100%;

  display: flex;
  min-height: calc(100vh - 89px - 186px);

  div.login_content {
    width: 100%;
    background-color: var(--ion-color-dark-shade);
  }

  .desktop_image {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  div.login {
    flex-direction: row;
    min-height: calc(100vh - 89px - 80px);
    // height : 100vh;

    div.login_content {
      padding-top: 0;
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .desktop_image {
      display: block;
      object-fit: cover;
      width: 50%;
      height: calc(120vh - 50px);
      position: sticky;
      top: 0;
      right: 0;
      // background: url("../theme/images/Groupe_build.png") no-repeat;
    }
  }
}
