@import "../theme/vars";

.block_message {
  width: 100%;
  min-height: 35px;
  margin: 12.5px 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-right: 0.5em;
}

.block_message.success {
  border: solid 1px var(--ion-color-success);
  color: var(--ion-color-light-contrast);
  background-color: rgba(var(--ion-color-success), 0.26);
  
}

.block_message.error {
  border: solid 1px var(--ion-color-danger);
  color: var(--ion-color-danger);
}

.block_message.info {
  border: solid 1px var(--ion-color-primary);
  color: var(--ion-color-primary);
}

.block_message .message_icon {
  height: 21px;
  min-width: 21px;
  padding: 7px 10px;
}

.content_message {
  padding: auto 0;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
}
