@import "../theme/vars";

ion-header.header_connected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 89px;
  padding: 0 13px;
  border-bottom: solid 1px #d6d6d6;
  background-color: var(--ion-color-primary-contrast);
}

ion-header.header_connected-desktop {
  ion-toolbar {
    --background: var(--ion-color-primary-contrast);
  }
}

ion-item.darkItem::part(native) {
  background-color: var(--ion-color-primary-contrast);
}
ion-item.darkItem {
  .component-icon-dark {
    color: var(--ion-color-light-contrast);
    font-size: 2em;
  }
}

.container_logo,
.logo_container {
  display: flex;
  align-items: center;
  color: var(--ion-color-light-contrast);
  .espace-loc_text {
    margin-left: 1em;
  }
}

.disconnect_link {
  color: var(--ion-color-light-contrast);
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 0.9em;

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.1);
    border: 3px solid black;
    border-radius: 5px;
  }
}

.disconnect_link > ion-icon {
  margin-right: 7px;
  width: 14px;
}

.header_logo {
  width: 50px;
  border-radius: 5px;
}

ion-item.espace-loc_text {
  width: 150px;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #dddddd;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
  &::part(native) {
    --background: var(--ion-color-primary-contrast);
  }
}

.span_loc {
  font-weight: 700;
}

ion-content.menu_container {
  --ion-background-color: var(--ion-color-primary-contrast);
}

.button_menu-icon {
  width: 60px;
}

.menu_icon {
  width: 50px;
  height: 60px;
  color: var(--ion-color-light-contrast);
}

.close-menu_icon {
  width: 30px;
  font-size: 30px;
}

ion-button.new_message_button {
  &::part(native) {
    --background-color: var(--ion-color-secondary);
  }
}

.mobile_tab-nav-container {
  height: 70px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  transition: transform ease-in-out .2s;

  &.hide, &.keyboard-open {
    transform: translateY(75px);
  }
}

.mobile_tab-nav {
  display: flex;
  height: 100%;
}

ion-button.nav_button::part(native) {
  &:hover,
  &:focus,
  &:focus-within {
    color: var(--ion-color-medium);
  }
}
.desktop_tab-nav .nav_button {
  --background: none;
}

.nav_button {
  --box-shadow: none;
  --border-radius: 0;
  --background-focused: var(--ion-color-success);

  background: var(--ion-color-medium-contrast);
  font-family: "montserrat", sans-serif;
  text-decoration: none;
  color: var(--ion-color-light-contrast);
  font-weight: 600;
  font-size: 0.7em;
  height: 100%;
  width: calc(100% / 3);
  margin: 0;

  ion-icon {
    font-size: 20px;
    margin-bottom: 5px;
    color: var(--ion-color-light-contrast);
  }

  &.dark {
    &:hover ion-icon,
    &:focus ion-icon,
    &:focus-within ion-icon {
      color: var(--ion-color-medium) !important;
    }

    &:hover,
    &:focus-within {
      background-color: var(--ion-color-success) !important;
      color: var(--ion-color-medium) !important;
    }
  }

  &.light {
    &:hover,
    &:focus-within {
      background-color: var(--ion-color-secondary) !important;
    }
  }

  &:hover ion-icon,
  &:focus ion-icon,
  &:focus-within ion-icon {
    color: var(--ion-color-medium);
  }

  &:hover,
  &:focus-within {
    background-color: var(--ion-color-success) !important;
    //color: var(--ion-color-medium);
  }
  &:focus-visible,
  &:focus-within {
    border: 3px solid black;
    border-radius: 5px;
  }
  &::part(native) {
    color: var(--ion-color-light-contrast);
    &:hover {
      color: var(--ion-color-light);
    }
  }
}
.flex_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-transform: none;
  font-size: 1em;
}

.new_message_button {
  color: var(--ion-color-light-contrast);
  background: var(--ion-color-secondary);
  ion-icon {
    color: var(--ion-color-text-color);
  }
  &:hover,
  &:focus-within {
    color: var(--ion-color-medium-contrast);
    ion-icon {
      color: var(--ion-color-medium-contrast);
    }
  }
  @media screen and (max-width: 1135px) {
    color: var(--ion-color-light-contrast);
    &:hover,
    &:focus-within {
      color: var(--ion-color-medium-contrast);
      ion-icon {
        color: var(--ion-color-medium-contrast);
      }
    }
  }
  &.light {
    background: var(--ion-color-success) !important;
    color: var(--ion-color-text-color);
    @media screen and (max-width: 1135px) {
      color: var(--ion-text-color);
    }
    &:hover,
    &:focus-within {
      background: var(--ion-color-secondary) !important;
      color: var(--ion-color-light-contrast);
      ion-icon {
        color: var(--ion-color-light-contrast);
      }
    }
  }
}

.toolbar_header-top {
  height: 120px;
  border-bottom: 1.5px solid #dddddd;
}
.toolbar_header-bottom {
  padding-left: 12px;
  padding-right: 12px;
  height: 60px;
}

.toolbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-right: 0;
}

.toolbar_header-bottom,
.toolbar_header-top {
  display: flex;
  justify-content: center;
}

:host(a) {
  font-size: 32px;
}

.bottom-nav_desktop {
  height: 100%;
  width: 100%;
}

.ul_desktop-nav {
  height: 60px;
  padding: 0;
  margin: 0;
  display: flex;

  li {
    list-style-type: none;
    position: relative;
  }
}

.button_nav-bar_desktop {
  contain: none;
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--ion-color-light-contrast);
  text-decoration: none;

  &::after {
    transform: scaleY(1);
  }

  &:hover {
    text-shadow: 0 0 0.2px black;
  }

  &:hover::after {
    animation: menu-bar-anim 0.3s ease-out;
    content: "";
    position: absolute;
    display: block;
    transform-origin: bottom;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    height: 4px;
    width: 100%;
    background-color: var(--ion-color-success);
  }

  &:focus {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@keyframes menu-bar-anim {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.active {
  text-shadow: 0 0 0.2px black;

  &::after {
    content: "";
    position: absolute;
    display: block;
    margin: 0 auto;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: var(--ion-color-success);
  }
}

@media screen and (min-width: 1135px) {
  .logo_container {
    width: 600px;
  }

  .logo_container--contract {
    margin-left: 70px;

    &_text {
      margin: 0;
      font-size: 1em;
      color: var(--ion-color-light-contrast);
      font-weight: bold;
    }

    button {
      padding: 0;
      font-size: 0.8em;
      text-decoration: underline;
      color: var(--ion-color-light-contrast);
      background: none;
    }
  }

  .header_logo {
    width: 90px;
  }

  .hot-news {
    height: 50px;
    padding: 0;
  }

  .header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .espace-loc_text {
    font-size: 1em;
  }

  .desktop_tab-nav {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 400px;
    height: 120px;

    .counter {
      position: absolute;
      color: $white;
      background: $redBorder;
      border-radius: 25px;
      padding: 5px;
      font-size: 10px;
      top: 19px;
    }
  }

  .mobile_tab-nav-container {
    height: 96px;
  }

  .nav_button {
    font-size: 0.8em;
    ion-icon {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}

.mobile_tab-nav-container {
  .nav_button {
    position: relative;
  }
  .counter {
    position: absolute;
    color: $white;
    background: $redBorder;
    border-radius: 25px;
    padding: 4px 5px 3px;
    font-size: 10px;
    top: 11px;
    line-height: 1;
  }
}
