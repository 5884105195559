@import "theme/vars";

.slider__background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100vw;
  height: 100%;
  background-color: var(--ion-color-medium-shade);
  overflow: hidden;
}

.container__slider {
  max-width: 450px;
  width: 100%;
  height: 100%;
  position: relative;

  background-color: var(--ion-color-dark-shade);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &--slides {
    width: inherit;
    height: 600px;
  }
}

.img__slider {
  margin-bottom: 30px;
}

.skip__button {
  font-size: 13px;
  color: var(--ion-color-light-contrast);
  background: none;
  position: absolute;
  top: 44px;
  right: 34px;
  text-decoration: underline;
  z-index: 100;
}

.container__content {
  height: 200px;
}

.slide__title {
  font-size: 1.1em;
  font-weight: 600;
  color: var(--ion-color-dark);
}

.slide__text {
  font-size: 0.7em;
  font-weight: 500;
  color: var(--ion-color-light-contrast);
  max-width: 300px;
  margin: auto;
}

.container__navigation {
  width: 70px;
  display: flex;
  justify-content: space-between;
}

.swiper-pagination-bullet {
  background: var(--ion-color-light-contrast) !important;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-success) !important;
}

.slide__next--button {
  background-color: var(--ion-color-dark-shade);
  height: 42px;
  margin-bottom: 44px;
  ion-icon {
    font-size: 2.9em;
    color: var(--ion-color-light-contrast);
  }
}

.check__icon {
  font-size: 20px;
  font-weight: 500;
  color: var(--ion-color-light-contrast);
}

.slide__previous--button {
  background-color: var(--ion-color-dark-shade);
  height: 42px;
  transform: rotate(180deg);
    ion-icon {
      font-size: 2.9em;
      color: var(--ion-color-light-contrast);
    }
}

.slide__button-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin: 0 auto;
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  .container__slider {
    max-height: 850px;
  }
}
