@import "../../theme/vars";

h1 {
  color: var(--ion-color-dark);
  font-size: 1.05em;
}

span.create_title {
  font-weight: bold;
  font-size: 1.23em;
}

.button-container {
  width: 294px;
  margin-top: 50px;
}

.button-container > *:not(:last-child) {
  margin-bottom: 20px;
}

.exist-button:focus-within::part(native) {
  box-shadow: 0 0 0 2px #fa9f18;
}

.input-society .input_icon {
  color: #d1cacf;
}

.input-contract .input_icon {
  font-size: 17px;
}

// Style for Desktop
@media screen and (min-width: 768px) {
  .input-birthday {
    max-width: 320px;
  }
}

.input-signup {
  margin-top: 20px;
  .input-wrapper {
    margin-top: 5px;
  }
}

#societyLabelledBy, #accountNumberLabelledBy, #birthdayLabelledBy {
  font-size: 0.8em;
  margin: 14.5px 0 0 0;
}

.input-signup .input-wrapper .side-item {
  &.tooltip-icon ion-icon {
    color: var(--ion-color-light-contrast);
  }
  ion-icon {
     color: var(--ion-color-medium);
 }
}