@import "../../theme/_vars.scss";

.top-banner_message {
  width: 100vw;
  height: 110px;

  display: flex;
  align-items: center;
  background-color: var(--ion-color-secondary);

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    margin: 0;
    margin-left: 20px;
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;
    line-height: 1;
  }

  ion-icon {
    font-size: 30px;
  }
}

.ionspinner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.new-message_content {
  background-color: var(--ion-color-light);
  .side-item--no-focus {
    cursor: pointer;
  }
}

.new-message_container {
  padding: 0 30px;
  padding-top: 30px;
  background-color: var(--ion-color-light);

  h2 {
    font-size: 1em;
    color: var(--ion-color-light-contrast);
  }
  label.new-message_title {
    font-size: 1em;
    color: var(--ion-color-light-contrast);
    margin-top: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.2;

    textarea {
      display: inherit;
    }
  }

  .new-message_subtitle {
    font-size: 0.8em;
    margin-left: 12px;
    &--info {
      color: var(--ion-color-danger);
    }
  }
}

#message-input {
  margin-top: 14.5px;
  color: var(--ion-color-medium);
}

.new-message_container_part {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &.hide {
    display: none;
  }
}

.new-message_select-object {
  border: none !important;
  padding-left: 20px;
  max-width: 340px;
  box-shadow: 0px 5px 15px #a496a01a;
}
.alert-wrapper.sc-ion-alert-md {
  min-width: 330px !important;
}

.new-message_container .input-wrapper .side-item ion-icon {
  color: var(--ion-color-medium);
}

.new-message_option-about_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 700px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  border: none;
  padding: 0;
  margin: 0;
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.new-message_radio-about {
  margin-bottom: 15px;
  max-width: 340px;
  width: 100%;
  height: 50px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 5px 15px #a496a01a;
  color: var(--ion-color-medium);

  @media screen and (min-width: 768px) {
    min-width: 340px;
  }

  p {
    font-size: 0.8em;
  }
}

.new-message_container_part_about {
  input[type="radio"]:checked + label {
    background-color: var(--ion-color-success-contrast);
    color: var(--ion-color-light-contrast);
    p {
      font-weight: bold;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.assur-container {
  min-width: 50%;
  .startdate-input, .enddate-input {
    cursor: pointer;

    .input.focusable {
      cursor: pointer;
    }
    
    .side-item_left {
      display: none;
    }
  }
}

.assur-select {
  .in-item.hydrated {
    font-weight: bold !important;
    color:  var(--ion-color-secondary);
  }
}

.new-message_input-message {
  width: 100%;
  max-width: 720px;
  min-height: 150px;
  border-radius: 14px;
  padding: 15px;
  font-size: 0.8em;
  border: none;
  resize: none;
  box-shadow: 0px 5px 15px #a496a01a;
}

.new-message_title {
  font-size: 1em;
  color: var(--ion-color-light-contrast);
}

.new-message_count {
  font-size: 13px;
  margin-bottom: 2em;
  color: var(--ion-color-light-contrast);
}

.new-message_file-title {
  display: flex;
  align-items: center;

  p {
    font-size: 0.8em;
    word-break: break-all;
    color: var(--ion-color-light-contrast);
  }

  ion-icon {
    margin-left: 10px;
  }

  .check-icon {
    color: var(--ion-color-success);
  }
}

.new-message_delete-file {
  margin-left: 20px;
  width: 100%;
  max-width: 70px;
  font-size: 0.8em;
  padding: 0 !important;

  text-decoration: underline;
  color: var(--ion-color-light-contrast);
  background: none;
  border: none;
}

.new-message_add-file_input {
  display: none;
}

.new-message_add-file {
  width: 200px;
  height: 30px;
  border-radius: 7px;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--ion-color-light-contrast);
  background-color: #a496a00d;
  margin-top: 4.5px;

  font-weight: bold;
  font-size: 0.8em;

  ion-icon {
    font-size: 20px;
    color: var(--ion-color-success);
  }
}

.new-message_add-file.disabled {
  opacity: 0.5;
  cursor: auto;
}

.new-message_submit {
  position: relative;
  margin-top: 30px;
  max-width: 340px;
  background-color: var(--ion-color-secondary) !important;
  color: var(--ion-text-color) !important;
  text-transform: uppercase;

  .spinner-circles {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.demand-not-enabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em auto;
  min-height: 15em;

  & p {
    text-align: center;
    width: 45%;
    color: var(--ion-color-danger);

    @media screen and (max-width: 768px) {
      width: 60%;
    }
    @media screen and (max-width: 468px) {
      width: 80%;
    }
  }

  & .go-back-btn {
    background-color: transparent;
    text-decoration: underline;
    &.dark {
      color: var(--ion-text-color);
    }
    &.light {
      color: var(--ion-color-dark);
    }
  }
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar, .alert-radio-group.sc-ion-alert-md::-webkit-scrollbar, .alert-message.sc-ion-alert-md::-webkit-scrollbar {
  display: unset;
  width: 10px;
  background-color: #ccc;
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-thumb, .alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-thumb, .alert-message.sc-ion-alert-md::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
