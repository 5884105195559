@import "../theme/vars";

.footer--light, .main_footer {
  background-color: var(--ion-color-light);
}

footer {
  background-color: $backgroundColor;
  @media screen and (min-width: 768px) {
    background-color: var(--ion-color-light);
  }
  width: 100%;
}

.main_footer {
  padding: 30px 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  color: var(--ion-color-light-contrast);
  font-size: 0.7em;

  ion-router-link,
  a {
    text-decoration: none;
    color: var(--ion-color-light-contrast);
    margin-bottom: 11px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

li.dlApp {
  font-weight: bold;
}

.link_CGU {
  margin-bottom: 11px;
}

div.mobileStore {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px 11px;

  ion-icon {
    margin-right: 3px;
  }
}

@media screen and (min-width: 900px) {
  .main_footer {
    height: 80px;
    flex-direction: row;
    list-style: none;
  }
}
.main_footer {
  list-style: none;
  & > * {
    margin: 5px;
  }
}

.open__slider {
  background: none;
  color: var(--ion-color-light-contrast);
  font-size: 1em;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.footer-icon {
  margin-right: 5px;
}
