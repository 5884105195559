@import "../theme/vars";

.background__modal {
  width: 100vw;
  height: 100vh;
  background-color: var(--ion-color-medium-shade);
  position: absolute;
  z-index: 10000000;
  animation: fade 0.2s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container__modal--contract {
  background-color: var(--ion-color-primary-contrast);
  z-index: 10000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0px 5px 15px #0000001a;
  padding: 30px 110px 90px 110px;
  max-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Hide scrollbar for Chrome, Safari and Opera */
&::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


  h1 {
    margin-left: 10px;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

.close_modal {
  margin-left: 100%;
  padding: 0;
  background: none;
  text-decoration: underline;
  color: var(--ion-color-light-contrast);
}

.list_contract {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  border: none;
  p {
    margin: 0;

    span {
      margin-left: 10px;
      font-size: 0.8em;
    }

    .status__dette {
      color: var(--ion-color-danger);
    }
  }

  label {
    margin: 10px 0;
    padding: 10px;
    color: var(--ion-color-light-contrast);

    &:hover {
      cursor: pointer;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type="radio"]:checked + label {
    background-color: var(--ion-color-success);
    border-radius: 7px;
    p {
      text-shadow: 0 0 0.5px var(--ion-color-light-contrast);
      color: var(--ion-color-light-tint);
    }
  }
}

.send__button {
  width: 290px;
  height: 48px;
  border-radius: 14px;
  background-color: var(--ion-color-success);

  text-transform: uppercase;
  color: var(--ion-color-light-tint);
  font-size: 1em;
  font-weight: bold;
}
