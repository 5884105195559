@import "../../theme/_vars.scss";

.messenger_content {
  background-color: var(--ion-color-light);
  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
}

.messenger_container {
  padding: 0 30px;
  padding-top: 30px;
}

.messenger_top_container {
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.messenger_title-container {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  ion-icon {
    font-size: 20px;
    color: var(--ion-color-dark);

    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }

  h1 {
    margin: 0;
    margin-left: 15px;
    font-size: 1.3em;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      font-size: 1.9em;
    }
  }
}

.base-button.green.messenger_link_new-message {
  display: flex;
  align-items: center;
  max-width: 250px;
  max-height: 55px;
  margin-bottom: 30px;
  font-size: 0.9em;
  color: var(--ion-color-light-tint);

  ion-icon {
    margin-right: 15px;
    font-size: 35px;
    color: var(--ion-color-dark-contrast);
  }
}

.messenger_list-messages_container {
  padding: 27px;
  width: 100%;
  box-shadow: 0px 5px 15px #a496a01a;
  border-radius: 14px;

  p {
    color: var(--ion-color-dark);
  }

  div > p:has(.request_ongoing) {
    margin: 0;
  }
}

.messenger_message-container {
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  &:not(:last-child) {
    border-bottom: 0.2px solid rgb(236, 235, 235);
  }
}

.messenger_list-message_date-container,
.messenger_list-message_content {
  display: flex;
}

.messenger_list-message_date-container {
  align-items: center;
  ion-icon {
    font-size: 21px;
    margin-right: 10px;
    color: var(--ion-color-dark);
  }

  .messenger_list-message_date {
    line-height: 2.5;
    font-size: 0.8em;
    font-weight: bold;
    color: var(--ion-color-light-contrast);
  }
}

.messenger_list-message_content {
  align-items: flex-start;
  p {
    margin: 0;
  }
  ion-icon {
    min-width: 20px;
    margin-left: 30px;
  }
}

.messenger_new-message_container {
  padding: 4px 7px;
  margin-right: 8px;
  border-radius: 7px;
  max-width: 60px;
  background-color: #e73331;
  display: flex;
  align-items: center;

  p {
    height: auto;
    margin: 0;
    font-size: 0.6em !important;
    color: #fff;
  }
}

.messenger_text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  height: 40px;

  @media screen and (min-width: 768px) {
    height: 17px;
  }
}

.in-progress {
  font-size: 0.8em;
  font-weight: bold;
  color: #bed400;
}

// DESKTOP table

.messenger_list-messages_container table {
  border-collapse: collapse;
  color: var(--ion-color-light-contrast);
}

.messenger_row_dekstop {
  height: 50px;
  margin: 0 auto;

  &:not(:last-child) {
    border-bottom: 0.2px solid rgb(236, 235, 235);
  }
  &:hover {
    cursor: pointer;
  }
}

.messenger_head_desktop {
  tr {
    height: 30px;
    th {
      text-align: left;
      font-size: 0.8em;
      font-weight: normal;
    }
  }
}

.messenger_top-cell_message {
  width: 55%;
  padding-left: 10px;
}

.messenger_cell_desktop {
  padding: 20px 0;
  color: var(--ion-color-light-contrast);

  p:not(.messenger_new-message_container) {
    margin: 0;
    font-size: 0.8em;
  }
}

.messenger_date_desktop {
  min-width: 155px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.messenger_origin_desktop {
  min-width: 100px;
}

.messenger_message_desktop {
  display: table-cell;
  padding: 0 10px;

  ion-icon {
    min-width: 25px;
  }
}

.messenger_type_desktop {
  width: 140px;
}

.messenger_date_desktop {
  display: flex;
  align-items: center;
  text-align: left;
  ion-icon {
    margin-right: 5px;
  }
}

.messenger_status_desktop {
  min-width: 80px;

  .request_ongoing ion-icon {
    margin-right: 4px;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  background-color: var(--ion-color-light-contrast);
  opacity: 0.5;
}

.load-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
