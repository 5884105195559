@import "../../theme/vars";

.notifications_page {
  ion-icon {
    pointer-events: none;
  }
  padding: 30px 0;
  background-color: var(--ion-color-light);
  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
  .notifications_title-container {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    ion-icon {
      font-size: 20px;

      @media screen and (min-width: 768px) {
        font-size: 30px;
      }
    }

    h1 {
      margin: 0;
      margin-left: 15px;
      font-size: 20px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 30px;
      }
    }
  }
  .notifications_list {
    padding: 0 15px;

    &.card {
      background: none;
    }

    @media screen and (min-width: 768px) {
      padding: 0 27px;
      width: 100%;
    }
  }
}

