@import "../theme/vars";

ion-header.header {
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ion-color-primary-contrast);
  @media screen and (min-width: 768px) {
    height: 120px;
  }
  .container_logo {
    margin-left: 30px;
  }

  .header_logo {
    width: 130px;
    border-radius: 5px;
  }
}
