.accomodation_faq-title {
  font-size: 30px;
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .faq_container_flex {
    display: flex;
    justify-content: center;
  }
}
