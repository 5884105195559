@import "../../theme/vars";

.requestDetail_content {
  background-color: var(--ion-color-light);

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
  p {
    font-size: 0.8em;
  }
}
.requestDetail_container {
  padding: 30px;
}

.requestDetail-demand_container {
  padding: 27px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0px 5px 15px #a496a01a;
  border-radius: 14px;
  color: var(--ion-color-light-contrast);

  th {
    text-align: left;
  }
}

.requestDetail_title-container {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  ion-icon {
    font-size: 20px;
    color: var(--ion-color-dark);

    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
  h1 {
    margin: 0;
    margin-left: 15px;
    font-size: 1.3em;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      font-size: 1.9em;
    }
  }
}

.requestDetail_return-container {
  height: 40px;
  border-bottom: 0.5px solid #e4e4e4;
}

.requestDetail_return-button {
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requestDetail_table-head-wrapper {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    border-bottom: none;
  }
}

.requestDetail_table-head {
  margin: 30px 0 00px;
  width: 100%;

  th {
    font-size: 14px;
    font-weight: normal;
    vertical-align: top;
    padding: 10px 0;
    display: flex;
    width: 100%;

    @media screen and (min-width: 768px) {
      display: table-cell;
      width: auto;
      padding: 10px 8px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    span {
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
    margin: 10px 0 0;
  }
}

.requestDetail_table-head_title-category {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 7px;

  @media screen and (min-width: 768px) {
    justify-content: center;
    display: table-row;
  }

  &.dark {
    background: #f4f4f40a;
  }

  .content-ref {
    display: flex;
    align-items: center;
    margin: 10px 0;

    p {
      margin: 0;
    }
  }
}

.request_ongoing {
  color: var(--ion-color-success);
  font-weight: bold;
  display: flex;
  align-items: center;
  min-width: 80px;
  white-space: nowrap;

  ion-icon {
    margin-right: 4px;
  }
}

.request_closed {
  color: var(--ion-color-light-contrast);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.requestDetail_container_messages-list {
  margin-bottom: 30px;
}

.requestDetail_date-cell {
  ion-icon {
    font-size: 20px;
    margin-right: 5px;
  }
  p {
    display: flex;
    align-items: center;
  }
}

.demandeList_message-cell {
  transition: height 0.3s ease-in;
  &__open {
    height: 100%;

    .demandeList_message-cell_content {
      overflow: auto;
      height: inherit;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .message-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
}
.demandeList_message-cell:not(:last-child) {
  border-bottom: 0.5px solid #e4e4e4;
}

.demandeList_message-cell {
  color: var(--ion-color-light-contrast);

  p {
    font-size: 16px;
  }
}

.demandeList_message-cell_content {
  overflow: hidden;
  height: 30px;
}

.semi-bold {
  font-weight: 600;
}

.requestDetail_button-file {
  height: 35px;
  width: 100%;
  margin-top: 30px;
  font-weight: bold;
  border-radius: 14px;
  color: var(--ion-color-light-contrast);
  @media screen and (min-width: 768px) {
    width: 380px;
  }
}

.requestDetail_button {
  width: 100%;
  height: 55px;
  border-radius: 14px;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;

  &--more-info {
    border: 2px solid $blueGreen;
    background-color: #fff;
    color: $blueGreen;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      max-width: 420px;
      text-align: center;
      padding-left: 0;
    }
  }

  &--solve-issue {
    background-color: $blueGreen;
    color: #fff;
    @media screen and (min-width: 768px) {
      max-width: 235px;
      margin-left: 15px;
      text-align: center;
      padding-left: 0;
    }
  }
}

.requestDetail_input-message {
  width: 100%;
}

.requestDetail_link-request {
  font-size: 0.8em;
  color: var(--ion-color-light-contrast);
}
