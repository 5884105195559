@import "../theme/vars";

.input-wrapper .input {
  flex: 1;
  padding: 0;
  height: 100%;
  --inner-padding-end: 0;
  --highlight-color-focused: none;
  --background: transparent;
  color: var(--ion-color-medium);
  &.ion-focused {
    border: 2px solid $focusOrange;
    border-radius: 15px;
  }
}

.input-wrapper .input::part(native) {
  align-items: center;
  height: 100%;
  padding-left: 0;
  --border-style: none;
}

.input-wrapper .input ion-select {
  font-size: 13px;
  height: 100%;
  color: var(--ion-color-medium);
}

.input-wrapper .input ion-select input {
  height: 100%;
  --placeholder-opacity: 1;
  --padding-left: 0px;
  --padding-right: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

ion-alert .alert-button-group button.alert-button {
  color: var(--ion-color-light-contrast);
}

ion-alert button.select-interface-option:focus-visible,
ion-alert button.alert-button:focus-visible {
  border: 1px solid black;
  border-radius: 4px;
}

.side-item_wrong {
  ion-icon {
    color: var(--ion-color-danger-tint) !important;
  }
}
