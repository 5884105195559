@import "../theme/vars";

.input-wrapper {
  position: relative;
  display: flex;
  background-color: #fff;
  border-radius: 15px;
  border: solid $border 1px;
  margin-top: 14.5px;
  font-family: "Montserrat", sans-serif;
}

.input-row {
  display: flex;
  flex: 1;
  align-items: center;
}

// Label

.input-wrapper .input ion-label {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-wrapper .input ion-label ion-icon {
  padding: 19px 16px;
  color: $border;
}

// Input

.input-wrapper .input {
  flex: 1;
  padding: 0;
  height: 100%;
  font-size: 13px;
  border: 0;
  background: transparent;
}

.input-wrapper .input:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
}

// Side

// .input-wrapper .input_icon {
//   color: #707070;
// }

.input-wrapper .side {
  height: 100%;
  position: relative;
  display: flex;
  height: 54px;
}

.input-wrapper .side:not(.side--no-border):before {
  content: "";
  position: absolute;
  height: 30px;
  width: 1px;
  background-color: $border;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.input-wrapper .side-item {
  width: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  color: var(--ion-color-success-contrast);

  &.small {
    font-size: 16px;
  }

  ion-icon {
    width: 21px;
    height: 22px;
    color: var(--ion-text-color);
  }
}

.side-item_wrong {
  ion-icon {
    color: var(--ion-color-danger-tint) !important;
  }
}

.input-wrapper .side-item:not(.side-item--no-focus):focus::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.input-wrapper button.side-item {
  outline: none;
  background-color: transparent;
}

.input_modal {
  position: absolute;
  background-color: white;
  font-size: 14px;
  padding: 15px;
  border-radius: 15px;
  width: 304px;
  border: 1px solid #d1cacf;
  top: 40px;
  right: -50%;
  transform: translateX(-50%);
  z-index: 99;
}

// Error

.input-error {
  font-size: 0.8em;
  margin-bottom: 2em;
  color: var(--ion-color-danger);
  width: 100% !important;

  @media screen and (min-width: 768px) {
    max-width: 428px;
  }
}

// Modifier

.icon_modifier {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a496a00d;
  border-radius: 7px;
  width: 40px;
  height: 40px;

  label:hover {
    cursor: pointer;
  }

  ion-icon {
    font-size: 25px;
    margin-right: 10px;
  }
  @media screen and (min-width: 768px) {
    width: 130px;
    label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .modifier_p {
      width: 100%;
      font-size: 0.9em;
      font-weight: 600;
    }

    ion-icon {
      font-size: 20px;
      margin-right: 0;
    }
  }
}
