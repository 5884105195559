@import "../../theme/vars";

.signup-create-page {
  span.h1-signup-create {
    font-weight: bold;
    font-size: 21px;
  }
}

@media screen and (min-width: 768px) {
  .input-wrapper {
    max-width: 428px;
  }
}

.signup_IonModal {
  --width: 90%;
  --height: auto;
  --max-width: 350px;
  --border-radius: 15px;

  .modal-wrapper {
    border-radius: 20px;
  }
  .ion-page {
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: var(--ion-color-light);
    p {
      text-align: center;
      color: var(--ion-color-light-contrast);

      .email_current {
        border-radius: 15px;
        border: solid #d1cacf 1px;
        margin-top: 14.5px;
        font-size: 13px;
        color: var(--ion-color-dark);
        background-color: var(--ion-color-primary-contrast);
      }
    }
  }
}

#passwordConfirmSignupCreateLabelledBy, #passwordSignupCreateLabelledBy, #emailSignupCreateLabelledBy {
  font-size: 0.8em;
  margin: 14.5px 0 0 0;
}

.signup-create_inputs .input-wrapper {
  margin-top: 5px;
}
