@import "../theme/vars";

.rent_history-container {
  padding: 25px 16px;
  border-radius: 14px;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;
}

.rent_history-table {
  color: var(--ion-color-light-contrast);
}

.rent_history-table th {
  text-align: left;
  color: var(--ion-color-light-contrast);  padding-bottom: 10px;
  font-size: 0.8em;
  font-weight: normal;

  &:nth-child(3) {
    text-align: right;
  }
}

.rent_history-table td {
  padding: 18px 0;
  font-family: "Montserrat";
  font-size: 0.8em;
}

.rent_history-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #e3dfe280;
}

.rent_history-table td {
  font-size: 0.8em;
}

.date_payment {
  font-weight: 600;
  display: flex;
  align-items: center;

  ion-icon {
    font-size: 15px;
    margin-right: 5px;
  }
}

.positive_payment {
  font-weight: 700;
  color: var(--ion-color-light-contrast);
  text-align: right;
  font-size: 0.8em !important;
}

.negative_payment {
  font-weight: 700;
  text-align: right;
  font-size: 0.8em !important;
}
