@import "../theme/vars";

.payment-title {
  text-align: center;
  margin: 2rem;

  &.light {
    color: var(--ion-color-medium);
  }
}

.back-link {
  display: block;
  margin: 2.5rem auto 1rem;
  text-align: center;
  padding: 0.7rem 1rem;
  border-radius: 0.8rem;
}

.payment-body {
  display: flex !important;
  flex-direction: column;
  min-height: auto;
  justify-content: center;
  align-items: center;
  margin: auto;
}

#kr-payment-body * {
  font-family: "Montserrat" !important;
}

#kr-payment-body .kr-card-header {
  border: none;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1.2rem;

  &-label {
    position: static;
  }

  .kr-card-icons {
    justify-content: center;
    margin-right: 0;
  }
}

#kr-payment-body {
  width: 350px;
}

#kr-payment-body .kr-field-element {
  border: none;
  border-bottom: 1px solid;
}

#kr-payment-body .kr-payment-button {
  margin-top: 50px;
  background-color: $button;
  border-radius: 14px;
  font-weight: bold;
  text-align: center;
}

.customerror {
  font-size: 0.9rem;
  color: var(--ion-color-danger);
}
