@import "../../theme/vars";

.h1_page {
  font-size: 1.23em;
  font-weight: 700;
  margin: 0;
}

.h3_page {
  font-size: 1em !important;
  margin: 0;
  font-weight: normal;
}

ion-text.create {
  .create_text {
    font-size: 0.8em;
    margin-top: 20.5px;
    width: 274px;
    .bold {
      font-weight: 700;
    }
  }
}

.login_form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .input-wrapper .side-item {
    &.tooltip-icon ion-icon {
      color: var(--ion-color-light-contrast);
    }
    ion-icon {
      color: var(--ion-color-medium);
    }
  }
}

.forgot {
  margin: 13px 0;
  font-size: 0.7em;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  color: var(--ion-color-light-contrast);
}

ion-button.connect_create {
  width: 250px;
}
ion-text.create {
  .create_text {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  ion-button.connect_create {
    width: 290px;
  }
}

.banner_connect_container {
  background-color: var(--ion-color-success-contrast);
}

.banner_connect_content {
  font-size: 16px;
  text-align: center;
  margin: 0;
  padding: 25px 50px;
  font-weight: 500;
  color: var(--ion-color-light-contrast);
}

.create_scroll {
  font-weight: 400;
  margin: 15px auto 0;
  display: block;
  color: var(--ion-color-light-contrast);
  background-color: var(--ion-color-success-contrast);
  text-decoration: underline;
}

#mailLoginLabelledBy,
#passwordLoginLabelledBy {
  font-size: 0.8em;
  margin: 14.5px 0 0 0;
}
