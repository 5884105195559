ul.skipLink {
    display: inline-block;
    padding: 0.5em;
    list-style-type: none;
    margin: 0;
    padding: 0;
    a {
      height: 50px;
      position: absolute;
      background-color: #555;
      top: -100px;
      color: #fff;
      text-decoration: none;
      font-size: 1rem;
      padding: 1rem;
      font-weight: 600;
      font-size: 1em;
      text-transform: uppercase;
      border-radius: 0px 10px 10px 10px;
      z-index: 1000000;
    }
  }
  ul.skipLink a:focus {
    top: 0;
  }
