@import "../../theme/vars";

.documents__content {
  padding-top: 60px;
  background-color: var(--ion-color-light);
}
.documents__main {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
}

.documents_list {
  max-width: 800px;
  @media screen and (min-width: 900px) {
    flex-grow: 2;
  }
}

.documents__title {
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
  font-family: "Montserrat";

  @media screen and (min-width: 900px) {
    font-size: 30px;
  }
}

.select-document__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .select__date {
    width: 40%;
  }
  .select__operation {
    width: 70%;
  }
  .input-wrapper .side-item ion-icon {
    color: var(--ion-color-medium);
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    width: 600px;

    .select__date {
      margin-right: 35px;
      width: 30%;
    }
    .select__operation {
      width: 70%;
    }
  }
}

.select__date .input::part(native),
.select__operation .input::part(native) {
  margin-left: 20px;
  color: var(--ion-color-light-contrast);
  font-size: 0.8em;
  font-weight: bold;
}
