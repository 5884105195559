//  {
//   width: 100%;
// }

// .pdf-viewer,
// .react-pdf__Page,
// .react-pdf__Document {
//   max-width: 100%;
//   width: 100%;
// }

// .pdfCanvas .react-pdf__Page__canvas {
//   height: 100% !important;
//   width: 100% !important;
// }

.pdf-viewer {
  position: relative;
  padding-top: 40px;
}

.pdf-viewer__header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: #333;
  color: #fafafa;
  z-index: 999;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pdf-viewer__header__name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  margin: 0 1em;
}
