.password-security-helper {
  background-color: transparent;
  width: auto;
}

.password-security-helper ion-item {
  color: #302d30;
  font-size: 12px;
  margin-bottom: -15px;
  background-color: transparent;

  ion-icon {
    width: 14px;
    margin-right: 7px;
    color: #a496a0;
  }
  ion-label {
    white-space: wrap !important;
  }
  &.dark {
    color: var(--ion-color-primary) !important;
  }
  .valid {
    color: #bed400 !important;
  }
  .invalid {
    color: var(--ion-color-danger) !important;
  }
}

.password-security-helper ion-item::part(native) {
  background-color: transparent;
}
