@import "../../theme/vars";

.main__container--legals {
  background-color: var(--ion-color-light);
  padding-top: 20px;
}

.legals__title {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.4em;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
}

.container-section-width--padded__legals {
  margin-bottom: 50px;
}

.legals__container {
  text-align: center;

  h1 {
    margin: 25px 0 50px;
    font-size: 2em;
    font-weight: 600;
    color: var(--ion-color-light-contrast);

    @media screen and (min-width: 768px) {
      font-size: 3em;
      margin: 40px 0 80px;
    }
  }

  p {
    margin: 0;
    font-size: 0.8em;
    color: var(--ion-color-light-contrast);
  }

  .back-button {
    max-width: 105px;
    display: flex;
    height: 38px;
    padding: 5px;
    font-weight: 600;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}
