@import "../../theme/vars";

.accomodation_container {
  padding: 40px 24px;
  background-color: var(--ion-color-light);
  [tabindex="1"]:focus-visible {
    border-radius: 4px;
  }
}

.accomodation-list_empty {
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  border-radius: 14px;
}

.accomodation_title {
  font-size: 20px;
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    font-size: 30px;
  }
}
.accomodation_faq-title {
  font-size: 20px;
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-bottom: 30px;
}

.accomodation-list_container {
  columns: 1;

  @media screen and (min-width: 960px) {
    columns: 2;
    column-gap: 10px;
  }
}

.accomodation-list_element {
    display: flex;
    flex-wrap: wrap;
    break-inside: avoid;
}

@media screen and (min-width: 768px) {
  .faq_container_flex {
    display: flex;
    justify-content: center;
  }
}
