@import "../../theme/vars";

.submit-button_profil_container {
  padding: 30px 30px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.reset-button_profil {
  margin: 10px auto;
  color: var(--ion-color-light-contrast);
  font-size: 12px;
  text-decoration: underline;
  background: none;
  align-self: flex-start;
}

.base-button.submit-button_profil {
  max-width: 350px;
  color: var(--ion-text-color);
  background-color: var(--ion-color-secondary) !important;
  text-transform: uppercase;
  font-weight: 600;
}

.submit-button_profil--fixed {
  @media screen and (max-width: 768px) {
    margin: 10px auto;
    padding: 0;
    position: fixed;
    z-index: 1000;
    top: 85%;
    left: 50%;
    transform: translateX(-50%) translateY(-90%);
    border-radius: 14px;
    background-color: var(--ion-color-primary-contrast);
  }
}

.toast_container_profile {
  margin-bottom: 72px;
  div:first-child {
    border-radius: 14px;
    font-weight: 500;
    font-size: 1em;
    color: #fff;
  }
  .Toastify__close-button {
    color: var(--ion-color-primary-contrast);
    opacity: 0.8;
  }
  .Toastify__toast--success {
    background-color: $greenBackground;
  }
  .Toastify__toast--error {
    background-color: var(--ion-color-danger);
  }
}

.profilDisplay {
  background-color: var(--ion-color-light);
  padding-top: 40px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 30px;

    .profilDisplay-right {
      flex: 1 1 40%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    .container_profile {
      flex: 1 1 auto;
    }
  }
}

.auto-payment_profile {
  padding: 2px 10px 20px 48px;
  background-color: var(--ion-color-light);
}

.toast_container_profile:has(.toast-success_reset-email) {
  left: 10% !important;
  transform: translateX(-10%) !important;
}

.toast-success_reset-email {
  width: 80vw !important;
}

.profile_form {
  background-color: var(--ion-color-light);
}
