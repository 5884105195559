@import "../theme/vars";

.aside__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0 25px 0;
  border-radius: 15px;
  max-width: 500px;
  box-shadow: 0px 5px 15px #a496a01a;
  padding: 0;

  @media screen and (min-width: 900px) {
    width: 350px;
    margin: 0 0 24px 20px;
  }
}

.aside__container ion-img {
  width: 60px;

  @media screen and (min-width: 900px) {
    width: 50px;
  }
}

.aside__text {
  width: 180px;
}

.aside__container h2 {
  font-family: "Montserrat";
  font-size: 1em;
  font-weight: bold;
  color: var(--ion-color-light-contrast);
}

.aside__container p,
.more-infos {
  font-family: "Montserrat";
  font-size: 0.8em;
  color: var(--ion-color-light-contrast);
}

.button__releve {
  --background: var(--ion-color-success);
  width: 190px;
  margin: 23px 46px 23px 0;
  color: var(--ion-color-medium);
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 0.8em;
  &.ion-focused {
    border: 2px $focusOrange solid;
    border-radius: 4px;
  }
}
