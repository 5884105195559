@import "../theme/_vars";

.close-menu_container {
  display: flex;
  margin-top: 40px;
  margin-right: 26px;
}

.close-menu__button {
  margin-left: auto;
  background-color: transparent;
  color: var(--ion-color-light-contrast);
}

.close-menu__button:focus {
  outline: none;
  position: relative;
}

.close-menu__button:focus::before {
  content: "";
  position: absolute;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.menu_container .main-nav {
  font-family: "Montserrat", sans-serif;
  padding-left: 33px;
  padding-right: 26px;
  margin-top: 10px;

  background-color: var(--ion-color-primary-contrast);

  display: flex;
  flex-direction: column;
}

.menu__list {
  padding-right: 26px;
  background-color: var(--ion-color-primary-contrast);

  ul {
    @media screen and (max-width: 800px) {
      padding-left: 0;
    }
  }
}

.menu__list .link {
  text-decoration: none;
  color: var(--ion-color-light-contrast);
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
  display: block;

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.menu__list .link {
  border-bottom: solid 1px #d5d5d5;
}

.menu_container .disconnect_link {
  margin-top: auto;
  height: auto;
}

.mobile_landscape {
  .close-menu_container {
    display: flex;
    margin-top: 20px;
    margin-right: 26px;
  }

  .main-nav {
    margin-top: 60px;
  }

  .menu__list .link {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    display: block;

    &:focus {
      outline: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.logo_container--contract__mobile {
  margin-top: 40px;
  font-weight: bold;
  color: var(--ion-color-light-contrast);
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    background-color: var(--ion-color-success) !important;
    padding: 10px;
    border-radius: 14px;
    color: var(--ion-color-light-tint);
  }
}

ion-menu ion-content {
  background-color: var(--ion-color-primary-contrast);
}
