.base-button {
  border-radius: 14px;
  font-size: 17px;
  width: 100%;
  height: auto;
  white-space: pre-wrap;
  font-family: "Montserrat";
  font-weight: bold;
  line-height: 20px;
  padding: 14px 28px;
  outline: none;
  border: none;
}

.base-button.green {
  background: var(--ion-color-success);
  color: var(--ion-color-medium);
}

.base-button.dark-green {
  background: var(--ion-color-secondary);
  color: var(--ion-text-color);
}

.base-button.small,
.base-button--a.small > div {
  border-radius: 7px;
  font-size: 13px;
  text-transform: none;
  padding: 8px 21px;
}

.base-button:focus-within,
.base-button--a.small:focus-within > div {
  box-shadow: 0 0 0 2px #fa9f18;
}

.base-button:disabled {
  opacity: 0.5;
}

.base-button--a {
  outline: none;
  text-align: center;
  text-decoration: none;
}

.hidden_button {
  display: none;
}
