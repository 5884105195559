.skeleton__loader {
  width: 90vw;
  height: 80px;
  background: var(--ion-color-secondary);
  border-radius: 15px;
  opacity: 0.7;
}

.loader_container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

@media screen and (min-width: 768px) {
  .skeleton__loader--faq {
    width: 60vw;
  }
}
