@import "../theme/vars";

.insurrance_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  &.is-assured {
    justify-content: unset;
    margin-bottom: 20px;
  }

  ion-icon {
    margin-right: 10px;
  }
  .check-icon {
    color: var(--ion-color-success);
  }
  .close-icon {
    color: $redText;
  }

  p {
    margin: 0 !important;
    font-weight: bold;
    color: var(--ion-color-light-contrast);
  }
}

.span_insurrance {
  font-weight: normal;
}

.no_insurrance {
  @media screen and (min-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.no_insurrance div {
  display: flex;
}

.renew-insurrance_button {
  min-height: 30px;
  padding: 10px;
  border-radius: 7px;
  color: var(--ion-color-light-contrast);
  font-size: 0.7em;
  font-weight: 600;
  width: initial;
  line-height: initial;

  & > .base-button {
    background-color: var(--ion-color-secondary-shade) !important;
    font-size: unset;
    line-height: unset;
  }
}
