@import "../../theme/vars";

.section_contact {
  padding: 40px 0;
  background-color: var(--ion-color-light);
  @media screen and (min-width: 480px) {
    padding: 40px 32px;
  }

  .contact-main-container {
    background-color: var(--ion-color-light-shade);
    padding: 32px;
    border-radius: 14px;
    display: inline-block;
    max-width: 800px;
    width: 100%;
  }
  
  .contact__button {
    margin-top: 0;
    @media screen and (min-width: 480px) {
      width: 300px;
      height: 70px;
    }
  }

  h1 {
    margin-bottom: 35px;
    font-size: 20px;
    font-weight: 700;
    color: var(--ion-color-dark);
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
  h2 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
    color: var(--ion-color-light-contrast);
  }
  p {
    font-size: 14px;
  }
  ion-icon {
    color: var(--ion-color-light-contrast);
    margin-right: 10px;
    pointer-events: none;
    flex-shrink: 0;
    
    &.icon-white {
      font-size: 1.2rem;
      color: var(--ion-color-light);
    }
    p {
      font-size: 0.8em;
      margin: 0;
      color: var(--ion-color-light-contrast);
    }
  }
}

.opening_hour,
.adress_contact {
  margin-bottom: 40px;
  color: var(--ion-color-light-contrast);
}
.opening_hour_container {
  display: flex;

  ion-icon {
    margin-top: 5px;
  }
}
.adress_contact,
.advisor-content {
  display: flex;

  p {
    margin: 0;
    color: var(--ion-color-light-contrast);
    font-size: 14px;
  }
}

.openingHourDay {
  text-transform: capitalize;
  font-weight: normal;
}

.table_opening {
  display: flex;
  margin-bottom: 10px;

  tr{
    td, th {
      font-size: 14px;
      padding: 5px 0;
      vertical-align: middle;
      text-align: left;
    }
    th {
      padding-right: 10px;
    }
  } 
}

.phone-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  gap: 20px 10px ;
}
.phone-block {
  max-width: 300px;
  flex-grow: 1;

  & span {
    font-size: 0.85em;
  }

  &.rdv-btn {
    order: 1;
  }
  &.phone-btn {
    order: 2;
    @media screen and (max-width: 768px) {
      order: 3;
    }
  }
}
.phone-text {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--ion-color-light-contrast);
  font-size: 14px;
  order: 3;
  margin-left: 26px;
  @media screen and (max-width: 768px) {
    &.order-2 {
      order: 2;
    }
    &.order-4 {
      order: 4;
    }
  }
}

.phone-container {
  display: flex;
  align-items: center;
  color: var(--ion-color-light-contrast);
  font-size: 0.8em;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: unset;
  }
}

.phone_button {
  display: block;
  width: 200px;
  height: 40px;
  margin: 0;
  border-radius: 7px;
  background-color: var(--ion-color-success);
  font-weight: 600;
  color: var(--ion-color-light);
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.contact__button {
  height: 55px;
  width: 250px;
  margin: 20px 0;
  border-radius: 7px;
  background-color: var(--ion-color-success);

  font-size: 1.1em;
  font-weight: 600;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &.dark {
    color: var(--ion-color-medium);
  }
  ion-icon {
    font-size: 30px;
    margin-right: 10px;
    color: var(--ion-text-color);
    &.dark {
      color: var(--ion-color-medium);
    }
  }
}

.skeleton__loader--contact {
  width: 100px;
}

.skeleton__loader--contact--opening {
  width: 300px;
}
