@import "../theme/vars";

.news {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: var(--ion-color-dark);
}

.news__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.news__articles {
  display: none;
  flex-direction: row;
  margin-top: 22px;
}

.news__articles--slider {
  width: 100%;
  margin-top: 22px;
}

.news__articles--slider .swiper-slide {
  width: 80%;
}

.news__articles--slider .swiper-slide:not(:last-child) {
  margin-right: 14px;
}

.news__articles--slider .news__article {
  max-width: unset;
}

.news__button {
  margin-top: 24px;
  max-width: fit-content;
}

// News article component

.news__article {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 33%;
  color: var(--ion-color-dark);
  text-decoration: none;
  border-radius: 6px;
  margin: 1px;
}

.news__article:focus {
  outline: none;
  box-shadow: 0 0 0 2px #fa9f18;
}

.news__article:not(:last-child) {
  margin-right: 22px;
}

.news__article_link {
  text-decoration: none;
}

.news__article__image {
  border-radius: 7px;
}

.news__article__title {
  font-weight: 600;
  font-size: 0.8em;
  line-height: 25px;
  color: var(--ion-color-light-contrast);
  text-align: left;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .news__articles {
    display: flex;
    padding: 0;
    .news__article_link {
      color: $darkColor;
    }
  }

  .news__article__title {
    font-size: 1.4em;
  }

  .news__articles--slider {
    display: none;
  }
}
