h1.lost-password {
  font-size: 21px;
  font-weight: bold;
}

.button-container {
  margin-top: 50px;
}

@media screen and (min-width: 768px) {
  .button-container {
    max-width: 294px;
  }
}
