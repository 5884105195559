@import "../theme/vars";

.faq_container {
  @media screen and (min-width: 768px) {
    width: 850px;
  }
  div {
    margin: 30px 0;
  }
}

.button_faq {
  background-color: var(--ion-color-secondary);
  width: 100%;
  height: 80px;

  padding: 0 30px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #fff;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;

  ion-icon {
    color: #fff;
  }

  h3 {
    font-size: 1em;
    font-weight: 600;
    margin: 14.5px 0;
  }
}

.question_container {
  padding-left: 50px;
  @media screen and (min-width: 768px) {
    padding-left: 40px;
  }
}

.question_title {
  margin-bottom: 20px;
  font-weight: 600 !important;
  color: var(--ion-color-light-contrast);
}

.question_text {
  font-size: 0.8em;
  color: var(--ion-color-light-contrast);
  width: 80%;
}
