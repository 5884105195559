@import "../theme/vars";

.modal-open .modal.show {
  position: fixed;
  top: 0;
  left: 800px;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  transform: translateX(-800px);
  transition: all 500ms ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.prompt_container {
  animation: fade 500ms;
  background-color:var(--ion-color-light);
  text-align: center;
  border-radius: 15px;
  padding: 22px;
  max-width: 500px;
  width: 80%;
  margin: 100px auto auto;
}

.prompt_body {
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--ion-color-light-contrast);
  font-size: 14px;
}

.prompt_footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.prompt_button {
  font-size: 14px;
  width: 65px;
  padding: 8px;
}
