@import "../theme/vars";

// Block in AuthLayout
.backdrop_custom_shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(0 0 0 / 30%) !important;
  z-index: 1;
  display: block;
  width: 100%;
}

.notifications_container {
  position: relative;
  max-width: 1650px;
  margin: auto;
  width: 100vw;
}

.notifications_content {
  border-radius: 15px;
  position: absolute;
  width: 350px;
  right: 192px;
  top: -40px;
  z-index: 10;
  padding: 15px;

  &>div {
    overflow: auto;
  }

  &:before {
    position: absolute;
    content: "";
    width: 0;
    top: -19px;
    left: calc(50% - 25px);
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 20px solid var(--ion-color-light-shade);
  }
}
.notif_link {
  text-decoration: none;
  display: block;
  background: #efefef;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;

  &.dark {
    background-color: var(--ion-color-secondary);

    ion-icon.notif_icon {
      background-color: var(--ion-color-light);
    }
  }

  &.notif-animated-background {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #efefef), color-stop(18%, #dddddd), color-stop(33%, #efefef));
    background: -webkit-linear-gradient(left, #efefef 8%, #dddddd 18%, #efefef 33%);
    background: linear-gradient(to right, #efefef 8%, #dddddd 18%, #efefef 33%);
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;

    &.dark {
      background: -webkit-gradient(linear, left top, right top, color-stop(8%, var(--ion-color-secondary)), color-stop(18%, var(--ion-color-light)), color-stop(33%, var(--ion-color-secondary)));
      background: -webkit-linear-gradient(left, var(--ion-color-secondary) 8%, var(--ion-color-light) 18%, var(--ion-color-secondary) 33%);
      background: linear-gradient(to right, var(--ion-color-secondary) 8%, var(--ion-color-light) 18%, var(--ion-color-secondary) 33%);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.notif_item_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--ion-color-light-contrast);
  font-size: 0.8em;
  font-weight: 500;
  height: 100%;
  align-items: center;
}

.notif_top_wrapper {
  align-items: center;
  padding: 15px 0;
}
.notif_bottom_wrapper {
  align-items: flex-start;
  padding: 0 0 15px;
}

ion-icon.notif_icon {
  font-size: 2em;
  color: $white;
  background: var(--ion-color-success);
  pointer-events: none;
  padding: 10px;
  border-radius: 10px;
  flex: 1.5 1 0;
}

.notif_text {
  max-width: 80%;
  margin: 0 15px;
  flex: 10 1 0;
}

.notif_chevron {
  font-size: 2em;
  color: var(--ion-color-success);
  flex: 1 1 0;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.notif_erase {
  margin-top: 15px;
  text-align: center;
  width: 100%;
  --background: var(--ion-color-success);
}

#notification_link {
  .empty {
    color: var(--ion-color-light-contrast);
  }
}
