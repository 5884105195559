@import "../../theme/vars";

.news-page .back-button {
  max-width: 105px;
  display: flex;
  height: 38px;
  padding: 5px;
  font-weight: 600;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.news_title {
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 1.3em;
  font-weight: bold;
  color: var(--ion-color-dark);
}
.news_articles_container {
  margin-bottom: 30px;
  a {
    text-decoration: none;
  }
}
.news_article_image {
  width: 100%;
  // aspect-ratio: 4/2;
  object-fit: cover;
  border-radius: 7px;
}
.news_article_title {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
}

.news_button-load {
  max-width: 220px;
  height: 35px;
  border-radius: 7px;

  padding: 8px 30px;
  background-color: var(--ion-color-success);
  font-size: 0.8em;
  color: var(--ion-color-medium);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.news_articles_container {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px 20px;
  }
  list-style: none;
  padding: 0;
}
