@import "../theme/vars";

.payment-confirm-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;

  & h1 {
    color: var(--ion-color-success);
    height: max-content;
    font-size: 1.5rem;
    line-height: 1.7rem;
    text-transform: uppercase;
    text-align: center;
  }

  & h5 {
    color: var(--ion-color-dark);
    height: max-content;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    &.dark {
      color: var(--ion-text-color);
    }
  }

  & h2 {
    color: var(--ion-color-medium);
    height: max-content;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    &.dark {
      color: var(--ion-text-color);
    }
  }

  & .back-link {
    margin-top: 2.5rem;
  }

  & > .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    height: 100%;

    & > .resume-container {
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
      min-width: 250px;

      & > .resume-details-container {
        display: flex;
        justify-content: space-between;
        color: var(--ion-color-medium);
        margin: 0.3rem 0;

        &--row {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 2rem 0;
          color: var(--ion-color-medium);

          &.dark {
            color: var(--ion-text-color);
          }
        }
        &.dark {
          color: var(--ion-text-color);
        }
      }
    }
  }
}
