$mainColor: #46535d;
$border: #d1cacf;
$greyBorder: #9ea5aa;
$redBorder: #e83331;
$greenBorder: #bed400;
$white: #ffffff;
$button: #bed400;
$backgroundColor: #f2f2f2;
$lightBackgroundColor: #f9f9f9;
$greenBackgroundColor: #bed4001a;
$redText: #e83331;
$darkColor: #363636;
$blueGreen: #4aa498;
$placeholderColor: #a496a0;
$focusOrange: #fa9f18;
$greenBackground: #bccf00;
