@import "../../theme/vars";

.container-default {
  padding: 44.5px 25px 45px 25px;
  font-family: "Montserrat", sans-serif;
  color: var(--ion-color-light-contrast);
}

.container-default--dashboard {
  padding: 39px 14px;
  @media screen and (min-width: 768px) {
    padding: 57px 10%;
    background: var(--ion-color-light);
  }
}
.container-default--dashboard > div {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.container-default--dashChildren {
  padding: 0;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.container-default--connect {
  background-color: var(--ion-color-light);
  @media screen and (min-width: 768px) {
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.container-default--connect > div {
  @media screen and (min-width: 768px) {
    width: 428px;
  }
}

.container-default--create {
  background-color: var(--ion-color-dark-shade);
  @media screen and (min-width: 768px) {
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.container-default--create > div {
  @media screen and (min-width: 768px) {
    width: 428px;
  }
}

@media screen and (min-width: 768px) {
  .container-default--footer {
    padding: 0;
    background-color: #fff;

    & > div {
      flex-direction: row;

      & > * {
        margin: 3px;
      }
      .mobileStore {
        & > * {
          margin-right: 3px;
        }
      }
    }
  }
}

.container-default--input {
  padding: 0;
  margin-top: 14.5px;
  border-radius: 15px;
  border: solid $border 1px;
  font-family: "Montserrat", sans-serif;
}

.container-default--signup {
  background-color: var(--ion-color-dark-shade);
  @media screen and (min-width: 768px) {
    height: 100%;
    min-height: 479px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.container-default--signup > div {
  @media screen and (min-width: 768px) {
    width: 428px;
  }
}

.container-default--disconnect {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100vw;
  height: 100%;
  background-color: var(--ion-color-medium-shade);
}
