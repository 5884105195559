@import "../theme/vars";

.container_accomodation-info {
  ion-icon {
    pointer-events: none;
  }
  height: fit-content;
  margin: 0 auto;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px #a496a01a;
  padding: 0;

  @media screen and (min-width: 768px) {
    width: 500px !important;
    margin-left: 30px;
  }

  @media screen and (max-width: 515px) {
    width: 500px !important;
    margin-left: 30px;

    .list_service {
      min-width: 140px;
    }
  }


  h2 {
    font-size: 1.3em;
    font-weight: bold;
    color: var(--ion-color-light-contrast);
    margin-right: 8px;
  }
  p {
    margin: 20px 0;
    font-size: 0.8em;
    font-weight: bold;
    color: var(--ion-color-light-contrast);
  }
  .container_accomodation-section {
    padding: 30px;

    &__rounded {
      border-radius: 0 0 14px 14px;
    }

    .insurrance_container {
      margin-top: 25px;
    }

    .renew-insurrance_button {
      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
      margin-top: 14px;
    }
  }
}

.accomodation_value {
  font-size: 1.3em;
  color: var(--ion-color-secondary-shade);
}

.adress_container {
  margin-top: 25px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  ion-icon {
    &.adress-icon {
      color: var(--ion-color-light-contrast);
    }
    margin-right: 10px;
  }
  p {
    margin: 0;
  }
}

.services_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title_services {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
}

.list_service {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 400px;
  position: relative;
  cursor: pointer;
  margin: 10px 0;
  padding: 0 15px;
  height: 60px;
  border-radius: 16px;
  background-color: var(--ion-color-success);
  font-size: 0.9em;
  font-weight: 600;
  color: var(--ion-color-dark);
  text-decoration: none;
  
  &.dark {
    color: var(--ion-color-dark-contrast);
  }

  // a {
  //   font-size: 0.8em;
  //   color: var(--ion-color-dark-contrast);
  //   text-decoration: none;
  // }

  &_logo {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.link_consumption {
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  a {
    font-size: 0.8em;
    color: var(--ion-color-light-contrast);
    margin: 10px 0;
  }

  ion-icon {
    color: $button;
    margin-right: 10px;
  }
}

.accomodation_dark-background {
  background-color: var(--ion-color-light);
}

.container_accomodation-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  p {
    margin: 0 0 10px 0;
  }

  .subtitle_UGCode {
    margin: 0;
    padding: 10px;
    background-color: var(--ion-color-light);
    border-radius: 14px;
  }
}
