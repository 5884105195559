@import "../theme/vars";

.documents {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: var(--ion-color-dark);
}

.documents-dashboard__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 0;
}

.documents__card {
  box-shadow: 0px 5px 15px #a496a01a;
}

.documents__button {
  margin-top: 24px;
  max-width: fit-content;
}

// Download button

.download-btn {
  text-decoration: none;
  // Allows .focusable to work properly
  margin-right: 2px;
  border-radius: 7px;
}

.download-btn__button {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background-color: #a496a00d;
  font-weight: bold;
  color: var(--ion-color-light-contrast);
  border-radius: 7px;
}

.download-btn__button__icon {
  font-size: 19px;
  color: var(--ion-color-success);
}

@media screen and (max-width: 768px) {
  .download-btn__button span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
  }
}

@media screen and (min-width: 768px) {
  .download-btn__button__icon {
    margin-left: 0.5em;
  }
}

