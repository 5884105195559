.hot-news {
  background:  var(--ion-color-success);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.hot-news_text {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em;
  color: var(--ion-color-light-tint);
}
