@import "../../theme/vars";

section.sitemap {

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    li {
        margin: 1em 0;
        &::before {
            content: "\2022";
            color: var(--ion-color-secondary); 
            font-weight: bold; 
            display: inline-block; 
            width: 3em; 
            margin-left: -1em; 
          }
    }
    
    a {
        text-decoration: none;
        color: var(--ion-color-light-contrast);
        font-weight: 600;
        font-size: 1.2em;
    }
}

