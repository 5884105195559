@import "../theme/vars";

.rent {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: var(--ion-color-dark);
}

.rent__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

// .rent__card {
//   margin-top: 22px;
// }

.rent__total {
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.rent__total p {
  margin: 0;
}

.rent__price {
  font-size: 46px;
  line-height: 57px;
  font-weight: bold;
}

.rent__disclaimer {
  font-weight: 500;
  margin: 0;
  margin-top: 4px;
}

.rent__info {
  display: flex;
  margin-top: 22px;
}

.rent_info_icon {
  color: var(--ion-color-success);
  font-size: large;
}

.rent__info p {
  margin: 0;
  margin-left: 0.5em;
}

.rent__button {
  margin-top: 22px;
}

@media screen and (min-width: 768px) {
  .rent__total {
    justify-content: flex-start;
  }

  .rent__button {
    margin-top: 50px;
    max-width: 341px;
  }
}
