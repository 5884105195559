span.mail-confirmation {
  font-size: 21px;
  font-weight: bold;
}

p.mail-confirmation {
  width: 80%;
  @media screen and (min-width: 768px) {
    width: 400px;
  }
}
