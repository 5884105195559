@import "../theme/vars";

table caption {
  text-align: left;
}

.preference_title {
  font-size: 20px;
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 30px;
}
.preference_fieldset {
  border: none;
}

//table
.bold_text {
  font-weight: 600;
}
.myprofile_table-wrapper {
  overflow-x: auto;
}

.myprofile_table tbody tr:not(:last-child) {
  border-bottom: 1.5px solid #e3dfe280;
}
.myprofile_table {
  width: 100%;
  tbody {
    color: var(--ion-color-light-contrast);
  }
}

.myprofile_table tr {
  padding: 18px 0;
  max-width: inherit;
  display: flex;
  gap: 10px;
  align-items: center;
}

.myprofile_table td:first-child {
  font-family: "Montserrat";
  font-size: 13px;
  width: 90%;

  &:hover {
    cursor: pointer;
  }
}

.myprofile_table td:last-child {
  width: 10%;
  display: flex;
  flex-direction: row-reverse;
}
// Slide style

.switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 32px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  top: 2px;
  background-color: #f2f2f2;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $blueGreen;
}
input:checked + .slider:before {
  transform: translateX(13px);
}

label.switch:focus-visible {
  border-radius: 20px;
  border: 2px solid $focusOrange;
}
