@import "../theme/vars";

ion-app {
  margin-top: env(safe-area-inset-top);
  // margin-bottom: env(safe-area-inset-bottom)
}

$containerWidth: 1600px;
$containerWidthSection: 1080px;

ion-content {
  --keyboard-offset: 0px !important;
  --padding-bottom: 70px;
  &::part(scroll) {
    background-color: var(--ion-color-primary-contrast);
  }
}

.container-width {
  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
}

.container-width--padded {
  max-width: $containerWidth + 28px;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0 auto;
}

.container-section-width--padded {
  max-width: $containerWidthSection + 28px;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0 auto;
}

.card {
  background-color: var(--ion-color-light-shade);
  border-radius: 15px;
  padding: 15px 20px;
  box-shadow: 0px 5px 15px #a496a01a;
}

.custom-updateapp-alert {
  --ion-backdrop-opacity: 0.5;

  .alert-wrapper {
    padding: 20px 10px;
    border-radius: 12px;
  }

  .alert-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-wrap: balance;
    margin-bottom: 2rem;
  }

  .alert-sub-title {
    font-size: 14px;
    font-weight: 600;
    color: var(--ion-color-dark) !important;
  }

  .alert-message {
    font-size: 14px;
  }

  .alert-title.sc-ion-alert-md,
  .alert-sub-title.sc-ion-alert-md {
    color: var(--ion-color-warning-contrast);
  }

  .custom-btn-updateapp-alert,
  .custom-btn-updateapp-alert:focus {
    background: var(--ion-color-success);
    color: var(--ion-color-warning-contrast) !important;
    border-radius: 8px;
    padding: 10px 20px;
    font-weight: bold;
    margin-bottom: 10px !important;
    margin: auto;
  }
  .custom-btn-updateapp-alert-cancel,
  .custom-btn-updateapp-alert-cancel:focus {
    background: var(--ion-color-medium);
    color: var(--ion-text-color) !important;
    border-radius: 8px;
    padding: 10px 20px;
    font-weight: bold;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .container-width--padded {
    max-width: $containerWidth + 64px;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }

  .container-section-width--padded {
    max-width: $containerWidthSection + 64px;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1135px) {
  ion-content {
    --padding-bottom: unset;
  }
}

.focusable:focus-within {
  outline: none;
  box-shadow: 0 0 0 2px #fa9f18;
}

// Utils
.strong {
  font-weight: bold;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

// Override

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  opacity: 0;
  z-index: 0;
  transform: translateX(60px);
  width: 80%;
  height: 100%;
  border: 0;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid red;
}

ion-select::part(icon) {
  display: none;
}

.skeleton_loader--table {
  margin: 10px;
}

.Toastify__toast-body div:last-child {
  color: var(--ion-color-primary-contrast);
}

.container-messageMaintenance {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-messageMaintenance {
  color: red;
}

.hide {
  display: none;
}

.spinner-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 40rem;
  padding-top: 3rem;
}
